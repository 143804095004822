/* General App Styles */
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Calendar Styles */
.Calendar {
  min-height: 600px !important;
  /* Adjust based on your header/footer height */
}

/* Modal Styles */
.modal-dialog {
  max-width: 90% !important;
  max-height: 90vh !important;
  margin: auto !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.modal-content {
  overflow-y: auto !important;
  /* Allows scrolling within the modal if content is too long */
}

/* Legend Styles */
.legend {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.color-box {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 5px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .modal-dialog {
    max-width: 95% !important;
    max-height: 95vh !important;
  }

  .modal-body {
    font-size: 16px !important;
    /* Adjust as needed for mobile devices */
  }

  .modal-footer .btn {
    padding: 10px 20px;
    /* Larger buttons for easier interaction on mobile devices */
  }

  /* Add any specific styles for react-big-calendar on mobile devices */
  .rbc-calendar {
    /* Adjustments for smaller screens */
  }
}