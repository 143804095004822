#timePicker {
    z-index: 999;
}

.timepicker-modal.animation {
    animation-fill-mode: none !important;
    animation-name: none !important;
}

.wrap-text {
    word-wrap: break-word;
    /* Allow long words to be able to break and wrap onto the next line */
    white-space: normal;
    /* CSS property to allow wrapping */
}