@media (min-width: 1400px) {

    main,
    #main-navbar {
        padding-left: 240px !important;
    }
}

.dropdown-toggle:after {
    display: none !important;
}